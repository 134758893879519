import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { APIS, WHERE_TO_BUY, IMAGE_BASE_URL } from "./../../../constants";
import "./../styles/Home.scss";
const $ = window.$;

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            searchText: "",
            productType: [],
            countryId: [],
            documentType: [],
            offset: 0,
            limit: 16,
            pages: 0,
            pageNo: 1,
        };
        this.getPdfUrl = this.getPdfUrl.bind(this);
        this.filterProducts = this.filterProducts.bind(this);
        this.getProductSlug = this.getProductSlug.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleDocumentChange = this.handleDocumentChange.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.filterCountries = this.filterCountries.bind(this);
        this.updatePage = this.updatePage.bind(this);
    }

    componentDidMount = () => {
        setTimeout(() => this.filterProducts(), 500);
    }

    filterProducts = () => {
        let products = [].concat(this.props.airPurifierProducts, this.props.wallMountProducts, this.props.concealedCeilingProducts, this.props.cassetteProducts, this.props.floorStandingProducts, this.props.ceilingMountedProducts, this.props.ceilingSuspendedProducts, this.props.rooftopProducts, this.props.vrvIndoorProducts, this.props.vrvOutdoorProducts);
        console.log('Pr', products.length);
        this.setState({
            products: products && products,
            pages: Math.ceil(products.length / 16),
            pageNo: 1,
            offset: 0,
            limit: 16
        });
        // this.updateDots();
    }

    getProductSlug = (catId) => {
        let slug = '';
        switch (catId) {
            case 1:
                slug = 'wall-mount-products';
                break;
            case 2:
                slug = 'air-purifier-products';
                break;
            case 3:
                slug = 'concealed-ceiling';
                break;
            case 4:
                slug = 'cassette';
                break;
            case 5:
                slug = 'floor-standing';
                break;
            case 6:
                slug = 'ceiling-mounted';
                break;
            case 7:
                slug = 'rooftops';
                break;
            case 8:
                slug = 'vrv-outdoors';
                break;
            case 9:
                slug = 'vrv-indoors';
                break;
            case 10:
                slug = 'ceiling-suspended';
                break;
            default:
                break;
        }
        return slug;
    }

    getEncodedTitle = (seriesName) => {
        let encodedData = 'Find My Product | Daikin MEA';
        if (localStorage.getItem("selectedCountry") !== null && localStorage.getItem("selectedCountry") !== 'Select Country' && localStorage.getItem("selectedCountry") !== '') {
            encodedData = seriesName + ' | ' + localStorage.getItem("selectedCountry") + ' | ' + encodedData;
        } else {
            encodedData = seriesName + ' | ' + encodedData;
        }
        return btoa(encodedData);
    }

    getPdfUrl = (url) => {
        let path = '';
        path = url.substring(url.lastIndexOf('/') + 1);
        path = APIS.BASE_URL + '/storage/product-documents/ONE-PG-' + path;
        path = path.replaceAll('%20', '-');
        return path;
    }

    handleReset = (e) => {
        let data = {
            countryId: '',
            productType: '',
            documentType: '',
            searchText: '',
        };
        this.props.history.push("/");
        this.props.filterProducts(data);
    }

    handleCountryChange = (e, id) => {
        let countryId = this.state.countryId;
        if (e.target.checked) {
            countryId.push(id);
        } else {
            let index = countryId.indexOf(id);
            if (index > -1) {
                countryId.splice(index, 1);
            }
        }
        // console.log(countryId);
        this.setState({
            countryId: countryId,
        });
        setTimeout(() => this.handleSubmit(e), 100)
    };

    handleProductChange = (e) => {
        // localStorage.setItem("productType", e.currentTarget.value);
        let productType = this.state.productType;
        if (e.target.checked) {
            productType.push(e.currentTarget.value);
        } else {
            let index = productType.indexOf(e.currentTarget.value);
            if (index > -1) {
                productType.splice(index, 1);
            }
        }
        // console.log(productType);
        this.setState({
            productType: productType,
        });
        setTimeout(() => this.handleSubmit(e), 100)
    };

    handleDocumentChange = (e) => {
        // localStorage.setItem("documentType", e.currentTarget.value);
        let documentType = this.state.documentType;
        if (e.target.checked) {
            documentType.push(e.currentTarget.value);
        } else {
            let index = documentType.indexOf(e.currentTarget.value);
            if (index > -1) {
                documentType.splice(index, 1);
            }
        }
        // console.log(documentType);
        this.setState({
            documentType: documentType,
        });
        setTimeout(() => this.handleSubmit(e), 100)
    };

    handleSearchTextChange = (e) => {
        // localStorage.setItem("searchText", e.currentTarget.value);
        this.setState({
            searchText: e.currentTarget.value,
        });
    };

    handleSubmit = (e) => {
        let data = {
            countryId: this.state.countryId,
            productType: this.state.productType,
            documentType: this.state.documentType,
            searchText: this.state.searchText,
        };

        // this.props.history.push("/");
        this.props.filterProducts(data);
        setTimeout(() => this.filterProducts(), 500);
    };

    clearFilter = (e) => {
        var ele = document.querySelectorAll('input[type=checkbox]');
        for (var i = 0; i < ele.length; i++)
            ele[i].checked = false;
        this.setState({
            countryId: [],
            productType: [],
            documentType: [],
            // searchText: "",
        });
        let data = {
            countryId: [],
            productType: [],
            documentType: [],
            searchText: this.state.searchText,
        };
        document.getElementById('country-search').value = '';
        let elements = document.getElementsByClassName('countries');
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'block';
        }
        this.props.filterProducts(data);
        setTimeout(() => this.filterProducts(), 500);
    };

    filterCountries = (e) => {
        let text = e.currentTarget.value;
        let elements = document.getElementsByClassName('country');
        for (let i = 0; i < elements.length; i++) {
            let ele = elements[i].innerHTML.toLowerCase();
            // console.log(elements[i].innerHTML);
            if (ele.includes(text.toLowerCase())) {
                elements[i].parentElement.style.display = 'block';
            } else {
                elements[i].parentElement.style.display = 'none';
            }
        }
    }

    updateDots() {
        let pageItems = document.getElementsByClassName('pagination')[0].childElementCount;
        if (pageItems > 7) {
            document.getElementsByClassName('page-item')[pageItems - 3].innerHTML = '...';
        }
    }

    updatePage = (e, pageNo) => {
        // this.updateDots();
        this.setState({
            pageNo: pageNo,
            offset: this.state.limit * (pageNo - 1),
        });
    }

    render() {
        return (
            <Fragment>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="search-bar">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6>Find My Product</h6>
                                            <h3>
                                                Access Manuals, Specifications, and More
                                                <br className="d-none d-lg-block" />
                                                for Your Purchased Model
                                            </h3>
                                            <div className="row">
                                                <div className="col-lg-8 offset-lg-2">
                                                    <div className="search-form-container">
                                                        <form action="#">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="text-search"
                                                                placeholder="Type any keyword"
                                                                id="text-serch"
                                                                autoComplete="off"
                                                                onInput={(e) => this.handleSearchTextChange(e)}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        this.handleSubmit(e);
                                                                    }
                                                                }}
                                                            />
                                                            <input onClick={e => this.handleSubmit(e)} type="submit" value="Find" />
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="main-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div
                                    id="sidebar"
                                    style={{
                                        position: "sticky !important",
                                        position: "-webkit-sticky",
                                        top: 10
                                    }}

                                >
                                    <div className="section-title">
                                        <h6>FILTERS</h6>
                                        <span onClick={e => this.clearFilter(e)} className="clr-btn">Clear All</span>
                                    </div>

                                    <div id="filter-unique">
                                        <div
                                            className="accordion accordion-flush"
                                            id="accordionFlushExample"
                                        >
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingOne">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseOne"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseOne"
                                                    >
                                                        <img className="me-2" src="img/product-filter-icon.svg" alt="" />
                                                        PRODUCT TYPE
                                                    </button>
                                                </h2>
                                                <div
                                                    id="flush-collapseOne"
                                                    className="accordion-collapse show"
                                                    aria-labelledby="flush-headingOne"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="airPurifier"
                                                                value="air_purifier_products"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="airPurifier"
                                                            >Air Purifier</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="wallMounted"
                                                                value="wall_mount_products"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="wallMounted"
                                                            >Wall Mounted</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="concealedCeiling"
                                                                value="concealed_ceiling"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="concealedCeiling"
                                                            >Concealed Ceiling</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="cassette"
                                                                value="cassette"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="cassette"
                                                            >Cassette</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="floorStanding"
                                                                value="floor_standing"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="floorStanding"
                                                            >Floor Standing</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="ceilingSuspended"
                                                                value="ceiling_suspended"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="ceilingSuspended"
                                                            >Ceiling Suspended</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="ceilingMounted"
                                                                value="ceiling_mounted"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="ceilingMounted"
                                                            >Ceiling Mounted</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="rooftops"
                                                                value="rooftops"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="rooftops"
                                                            >Rooftops</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="vrvOutdoors"
                                                                value="vrv_outdoors"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="vrvOutdoors"
                                                            >VRV Outdoors</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="vrvIndoors"
                                                                value="vrv_indoors"
                                                                onChange={e => this.handleProductChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="vrvIndoors"
                                                            >VRV Indoors</label
                                                            >
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="accordion accordion-flush"
                                            id="accordionFlushExample2"
                                        >
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingTwo">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseTwo"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseTwo"
                                                    >
                                                        <img className="me-2" src="img/country-filter-icon.svg" alt="" />
                                                        COUNTRY
                                                    </button>
                                                </h2>
                                                <div
                                                    id="flush-collapseTwo"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="flush-headingTwo"
                                                    data-bs-parent="#accordionFlushExample2"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="dropdown mb-4">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search countries..."
                                                                id="country-search"
                                                                aria-expanded="false"
                                                                onChange={e => this.filterCountries(e)}
                                                                autoComplete="off"
                                                            />

                                                        </div>
                                                        <div className="countries-container">
                                                            {this.props.countries && this.props.countries.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="form-check countries">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            name="countryId"
                                                                            value={item.id}
                                                                            onChange={e => this.handleCountryChange(e, item.id)}
                                                                        />
                                                                        <label className="form-check-label country"
                                                                        >{item.name}</label
                                                                        >
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="accordion accordion-flush"
                                            id="accordionFlushExample3"
                                        >
                                            <div className="accordion-iteTwo">
                                                <h2 className="accordion-header" id="flush-headingthree">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapsethree"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapsethree"
                                                    >
                                                        <img className="me-2" src="img/doc-filter-icon.svg" alt="" />
                                                        DOCUMENT TYPE
                                                    </button>
                                                </h2>
                                                <div
                                                    id="flush-collapsethree"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="flush-headingthree"
                                                    data-bs-parent="#accordionFlushExample3"
                                                >
                                                    <div className="accordion-body">

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="documentType"
                                                                id="catalog_document"
                                                                value="catalog_document"
                                                                onChange={e => this.handleDocumentChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="catalog_document"
                                                            >Catalogue</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="documentType"
                                                                id="certificate_document"
                                                                value="certificate_document"
                                                                onChange={e => this.handleDocumentChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="certificate_document"
                                                            >Certificates</label
                                                            >
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="documentType"
                                                                id="iom_document"
                                                                value="iom_document"
                                                                onChange={e => this.handleDocumentChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="iom_document"
                                                            >Installation & Operation Manual</label
                                                            >
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="data_book_document"
                                                                value="data_book_document"
                                                                onChange={e => this.handleDocumentChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="data_book_document"
                                                            >Data Book</label
                                                            >
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="service_manual_document"
                                                                value="service_manual_document"
                                                                onChange={e => this.handleDocumentChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="service_manual_document"
                                                            >Service Manual</label
                                                            >
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="productType"
                                                                id="specification_guide"
                                                                value="specification_guide"
                                                                onChange={e => this.handleDocumentChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="specification_guide"
                                                            >Specification Guide</label
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div id="item-wrapper">
                                    {this.state.products && this.state.products.slice(this.state.offset, this.state.products.length < this.state.limit + this.state.offset ? this.state.products.length : this.state.limit + this.state.offset).map((item, index) => {
                                        return (
                                            <div key={index} className="item">
                                                {item.product_series_name !== undefined ? (
                                                    <img
                                                        src={
                                                            item.featured_image != null
                                                                ? IMAGE_BASE_URL +
                                                                "/storage" +
                                                                item.featured_image
                                                                : "./../img/logo.svg"
                                                        }
                                                        className="img-fluid img-fluid-height"
                                                        alt={item.product_series_name}
                                                    />
                                                ) :
                                                    // (item.download_status === 0 ?
                                                    (true ?
                                                        (<img
                                                            // src={APIS.BASE_URL + '/storage/product-documents/no-preview.png'}
                                                            src="./../../../img/file-placeholder.png"
                                                            className="img-fluid img-fluid-height"
                                                            alt={item.product_series_name}
                                                        />) : (<iframe
                                                            type="application/pdf"
                                                            frameborder="0"
                                                            width="100%"
                                                            height="100%"
                                                            seamless="seamless"
                                                            className="responsive-iframe"
                                                            src={this.getPdfUrl(item.document_url)}
                                                            title="description"
                                                            scrolling="no"
                                                        ></iframe>))
                                                }
                                                {item.product_series_name !== undefined ? (
                                                    <h6>
                                                        {item.product_series_name + " Series"}
                                                    </h6>
                                                ) : (
                                                    <>
                                                        <h6 style={{ marginTop: 10 }}>{item.document_name}</h6>
                                                    </>
                                                )}
                                                {item.product_series_name !==
                                                    undefined ? (
                                                    <Link
                                                        to={`/products/${this.getProductSlug(item.product_category_id)}/${item.id}/${item.product_series_name}?encoded_title=${this.getEncodedTitle(item.product_series_name)}&featured_image=${btoa(item.featured_image)}`}
                                                    >
                                                        VIEW DETAILS
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        target="_blank"
                                                        to={item.document_url.replace(
                                                            "https://",
                                                            "//"
                                                        )}
                                                    >
                                                        VIEW DETAILS
                                                    </Link>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.products.length > this.state.limit && (
                        <div className="pagination-main d-flex justify-content-center mt-5">
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    <li onClick={(e) => { this.updatePage(e, 1) }} className={`page-item ${this.state.pageNo == 1 ? 'disabled' : ''}`}>
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li onClick={(e) => { if (this.state.pageNo > 1) this.updatePage(e, this.state.pageNo - 1) }} className={`page-item ${this.state.pageNo == 1 ? 'disabled' : ''}`}>
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&lt;</span>
                                        </a>
                                    </li>

                                    {Array.from(Array(this.state.pages), (e, i) => {
                                        return (
                                            <li onClick={(e) => this.updatePage(e, i + 1)} className={`page-item ${i + 1 == this.state.pageNo ? 'active' : ''} ${(i + 1 >= this.state.pageNo && i < this.state.pageNo + 2) || (i > this.state.pageNo - 4 && this.state.pageNo == this.state.pages)  || (i > this.state.pageNo - 3 && this.state.pageNo == this.state.pages - 1) ? '' : 'hide'}`} key={i}>
                                                <a className="page-link" href="#">{i + 1}</a>
                                            </li>
                                        );
                                    })}

                                    <li onClick={(e) => { if (this.state.pageNo !== this.state.pages) this.updatePage(e, this.state.pageNo + 1) }} className={`page-item ${this.state.pageNo == this.state.pages ? 'disabled' : ''}`}>
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&gt;</span>
                                        </a>
                                    </li>

                                    <li onClick={(e) => { this.updatePage(e, this.state.pages) }} className={`page-item ${this.state.pageNo == this.state.pages ? 'disabled' : ''}`}>
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    )}

                    <div id="bcards">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="bcard">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <h6>CONTACT US</h6>
                                                <h4>Get in Touch with Daikin Support</h4>
                                                <p>
                                                    Reach out to our dedicated team for any inquiries,
                                                    support, or assistance. We're here to help you with all
                                                    your Daikin needs.
                                                </p>
                                                <a target="_blank" href="https://www.daikinmea.com/en_us/contact.html"> CONTACT US </a>
                                            </div>
                                            <div
                                                className="col-lg-4 d-flex align-items-center justify-content-center"
                                            >
                                                <img
                                                    src="img/headphone.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="bcard">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <h6>SALES NETWORK</h6>
                                                <h4>Find nearest Daikin dealer at your place</h4>
                                                <p>
                                                    Reach out to our dedicated team for any inquiries,
                                                    support, or assistance. We're here to help you with all
                                                    your Daikin needs.
                                                </p>
                                                <a target="_blank" href="https://www.daikinmea.com/en_us/dmea-sales-network.html"> WHERE TO BUY </a>
                                            </div>
                                            <div
                                                className="col-lg-4 d-flex align-items-center justify-content-center"
                                            >
                                                <img src="img/map.png" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default HomePage;

